import { StyleSheet, Text, View, Image } from 'react-native';

export default function App() {
  return (
    <View style={{ flex: 1, justifyContent: "space-evenly", alignItems: "center" }}>
      <Text adjustsFontSizeToFit={true}
        style={{ textAlignVertical: "auto", textAlign: "justify", margin: 20, letterSpacing:1.4 }}>You may have noticed that sometimes when you visit a website, it says "Brewing the site!" instead of loading immediately. This is because some websites use a special technology called "CoffeeScript" to write their code. CoffeeScript is a language that compiles to JavaScript, which is the standard language for web browsers. But before CoffeeScript can run on your browser, it needs to be brewed into JavaScript first. This process can take some time, depending on how complex the website is and how strong the coffee is. The funny fact is that some websites actually use real coffee machines to brew their code! They connect their coffee makers to their servers and use sensors to detect when the coffee is ready. Then they send the brewed code to your browser along with a nice aroma of freshly roasted beans. Isn't that amazing? Next time you see "Brewing the site!", you might want to grab a cup of coffee yourself and enjoy the wait!        </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
  },
  imageContainer: {
    flex: 1,
    paddingTop: 58,
  },
  image: {
    width: 320,
    height: 440,
    borderRadius: 18,
  },
  footerContainer: {
    flex: 1 / 3,
    alignItems: 'center',
  },
});
